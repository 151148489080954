import { cn } from '@/lib/utils'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { useGlobalChat } from 'domains/Ai/GlobalChatProvider'
import {
  FurtherFeedbackModal,
  ThumbsDown,
  ThumbsUp
} from 'domains/Ai/components/Feedback'

import Button from 'components/Button'
import { useGlobalModal } from 'components/GlobalModal'
import { SVGIcon } from 'components/Icon'

import { LearningGoal, useUpdateLearningGoalMutation } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { sentenceCase } from 'utils/stringUtils'
import { trackAiChatSessionOpened, trackExpandClick } from 'utils/tracking/analytics'

import { ReactComponent as CheckCircle } from 'images/p-check-circle.svg'
import { ReactComponent as ChevronDown } from 'images/thin-chevron-down.svg'
import { ReactComponent as ChevronUp } from 'images/thin-chevron-up.svg'

import DraftImg from './draft.png'
import PlaceholderImg from './placeholderDocument.png'
import PracticeImg from './practice.png'
import QuizImg from './quiz.png'
import TakeawayImg from './takeAway.png'

type TodoProps = {
  data: {
    sessionId: number
    stepIndex: number
    step: LearningGoal['data']['steps'][number]
    activeSession: LearningGoal
    refetchSessions: () => void
  }
}

export const Todo = ({ data }: TodoProps) => {
  const { step, stepIndex, activeSession } = data
  const [checked, setChecked] = useState(!!step?.checked)
  const completionDate = moment(step.completionDate).format('MM/DD/YYYY')
  const [thumb, setThumb] = useState<'up' | 'down' | null>(null)
  const [updateSession] = useUpdateLearningGoalMutation()
  const { pathname } = useLocation()
  const { currentUser } = useCurrentUser()
  const { openChatAndSendMessage, setIsChatOpen, setMode, setChatId, isChatOpen } =
    useGlobalChat()
  const [activeAction, setActiveAction] = useState<string | undefined>(undefined)

  const { openGlobalModal, closeGlobalModal } = useGlobalModal()

  const openFeedbackModal = () => {
    openGlobalModal(
      <FurtherFeedbackModal
        headerText="What do you dislike about this content?"
        subHeaderText="Your feedback will help us improve goal recommendations"
        sessionId={activeSession.id}
        sessionType="learning_goal"
        onClose={closeGlobalModal}
        removeDislike={() => {
          setThumb(null)
          closeGlobalModal()
        }}
        sendFeedback={async () => {
          // NOTE: prob doesn't need to be async. Lets cros the bridge when we get ther
          // TODO: Add tracking here
          console.log('feedback sent')
        }}
      />,
      {
        className:
          'w-full h-full max-h-full sm:h-full sm:max-h-full lg:h-auto lg:max-h-[calc(100%-80px)] lg:max-w-md lg:rounded-xl',
        containerClass: 'backdrop-blur-sm',
        fullWidth: true
      }
    )
  }

  const UncheckedBox = (
    <SVGIcon name="checkbox-empty" fill="#d3d2d3" width="20" height="20" />
  )
  const CheckedBox = (
    <SVGIcon name="checkbox-ticked" fill="#080A0A" width="20" height="20" />
  )

  const toggleCheckbox = async (index: number) => {
    const value = !checked

    setChecked(value)

    const payload = JSON.stringify({
      setChecked: {
        value,
        index
      }
    })

    await updateSession({
      variables: {
        input: {
          id: activeSession.id,
          data: payload
        }
      }
    })
  }

  const handleDraftClick = (promptKey: string, draftName: string) => {
    const draftId = uuid()
    const payload = JSON.stringify({
      setDraftId: {
        value: draftId,
        index: stepIndex
      }
    })

    setChatId(draftId)
    setMode({
      mode: 'document_generation',
      modeOptions: { template_name: promptKey, label: draftName }
    })

    openChatAndSendMessage(
      `Help me draft a ${draftName} document`,
      window.location.href,
      {
        mode: 'document_generation',
        modeOptions: {
          command: 'draft',
          template_name: promptKey,
          label: draftName
        }
      }
    )

    updateSession({
      variables: {
        input: {
          id: activeSession.id,
          data: payload
        }
      }
    })

    trackAiChatSessionOpened({
      mode: 'document_generation',
      chat_session_type: 'new',
      access_policy_kind: currentUser?.accessPolicyKind,
      chat_session_id: '',
      is_draft: true,
      is_suggested_prompt: true,
      path: pathname,
      location: 'goals',
      text: 'create a draft'
    })
  }

  const handleOpenChat = async (action: string, chatId: string | undefined) => {
    const activeChatId = chatId || uuid()

    if (chatId) {
      setChatId(chatId)
      setIsChatOpen(true)

      return
    }

    setChatId(activeChatId)
    setMode({
      mode: 'application',
      modeOptions: {
        action,
        sessionId: activeSession.id,
        stepIndex
      }
    })
    openChatAndSendMessage(action, 'learning-goals')
  }

  const handleViewDocument = (value: string) => {
    const payload = JSON.stringify({
      markTodoComplete: {
        value
      }
    })

    updateSession({
      variables: {
        input: {
          id: activeSession.id,
          data: payload
        }
      }
    })
  }

  const handleStepExpand = (stepTitle: string) => {
    const payload = JSON.stringify({
      toggleStepExpanded: {
        value: !step.expanded,
        index: stepIndex
      }
    })

    updateSession({
      variables: {
        input: {
          id: activeSession.id,
          data: payload
        }
      }
    })

    trackExpandClick({
      expandable_section_title: stepTitle.toLowerCase(),
      expandable_section_type: 'goals__action_plan_step',
      location: '/goals'
    })
  }

  const handleViewDraft = (chatId: string, promptKey: string, draftName: string) => {
    setMode({
      mode: 'document_generation',
      modeOptions: { template_name: promptKey, label: draftName }
    })
    setChatId(chatId)
    setIsChatOpen(true)
  }

  useEffect(() => {
    if (!isChatOpen) {
      setActiveAction(undefined)
    }
  }, [isChatOpen])

  const capitalizeEachSentence = (str: string) => {
    return str.split('. ').map(sentenceCase).join('. ')
  }

  const AiDraftCard = (props: any) => {
    const { iconImg, title, action, text, customClick } = props
    const chatIdName = `${action}ChatId`
    const chatId = Object.prototype.hasOwnProperty.call(step, chatIdName)
      ? (step as any)[chatIdName]
      : undefined

    const handleClick = () => {
      const payload = JSON.stringify({
        markTodoComplete: {
          value: `${stepIndex}_${action}`
        }
      })

      updateSession({
        variables: {
          input: {
            id: activeSession.id,
            data: payload
          }
        }
      })

      setActiveAction(action)
      handleOpenChat(action, chatId)
    }

    const borderColor = () => {
      switch (action) {
        case 'practice':
          return 'border-[#7D98F9]'
        case 'takeaway':
          return 'border-[#FFEADE]'
        case 'quiz':
          return 'border-[#961554]'
        case 'draft':
          return 'border-[#1A6C69]'
        default:
          return ''
      }
    }

    return (
      <div
        className={cn(
          'hover:shadow-[0px_4px_9px_0px_rgba(15,15,15,0.10)] shadow cursor-pointer',
          'rounded-md flex flex-col gap-4 tl:flex-row tl:items-center lg:flex-col lg:items-start lg:justify-center p-6 h-[187px] mb-6',
          action === activeAction && `border ${borderColor()}`
        )}
        onClick={() => {
          if (action === 'draft') {
            setActiveAction('draft')
            customClick()
          } else {
            handleClick()
          }
        }}
      >
        <img src={iconImg} className="h-10 w-10 tl:mr-4 lg:mb-4 lg:mr-0" />

        <div className="flex flex-col justify-center gap-2">
          <div className="font-sans font-semibold lg:mb-2 text-base">{title}</div>
          <div className="font-sans text-sm font-normal">{text}</div>
        </div>
      </div>
    )
  }

  return (
    <div className="font-sans rounded-xl p-6 pb-0 border bg-white border-rb-gray-100 w-full">
      <div
        className={cn(
          'text-rb-gray-400 font-semibold flex items-center',
          checked && 'text-[#B5B8B4]'
        )}
      >
        <div className="flex items-center w-full justify-between">
          <div
            className="flex cursor-pointer items-center gap-3"
            role="checkbox"
            tabIndex={0}
            aria-checked={checked}
            onClick={() => toggleCheckbox(stepIndex)}
          >
            <span className="mr-2.5">{checked ? CheckedBox : UncheckedBox}</span>
            <div className="h-8 font-normal rounded-[30px] bg-rb-gray-50 px-3 py-1 text-xs font-sans flex items-center justify-center">
              {`Complete by ${completionDate}`}
            </div>
            <div className="h-8 font-normal rounded-[30px] bg-rb-gray-50 px-3 py-1 text-xs font-sans flex items-center justify-center">
              {`${activeSession?.learningTime || 30} min`}
            </div>
          </div>
          {!checked && (
            <div className="-mt-4 flex items-center justify-between">
              <div className="flex items-center justify-start gap-2">
                <ThumbsUp
                  selected={thumb === 'up'}
                  onClick={() => {
                    // TODO: Add tracking here
                    setThumb('up')
                  }}
                />
                <ThumbsDown
                  selected={thumb === 'down'}
                  onClick={() => {
                    // TODO: Add tracking here
                    setThumb('down')
                  }}
                  onClickSendFeedback={openFeedbackModal}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="w-full flex items-center justify-between font-polysans text-2xl font-normal my-6">
        {step.stepTitle}
        {!checked && (
          <div
            className="flex items-center justify-center"
            onClick={() => handleStepExpand(step.stepTitle)}
          >
            {step.expanded ? (
              <ChevronDown height={20} width={20} />
            ) : (
              <ChevronUp height={20} width={20} />
            )}
          </div>
        )}
      </div>

      {!step.expanded && !checked && (
        <>
          <div className="text-rb-gray-400 text-sm font-normal mt-1">
            {capitalizeEachSentence(step.action)}
          </div>

          <div className="mt-4 p-3 pb-0 flex items-center flex-col justify-between rounded-xl">
            {[...step?.documents]
              ?.sort((a) => (a.type === 'Guide' ? -1 : 1))
              ?.map((document, index) => {
                const documentStepKey = `${stepIndex}_doc_${index}`

                return (
                  <div
                    key={index}
                    className="flex items-center justify-between flex-1 my-6 w-full"
                  >
                    <div className="flex items-center">
                      <div className="w-[80px] h-20 mr-3">
                        <div
                          className="w-[80px] h-20 rounded-lg"
                          style={{
                            backgroundImage: `url(${document?.metadata?.thumbnail || PlaceholderImg})`,
                            backgroundPosition: 'center', // Centers the background imag
                            backgroundSize: 'cover' // Ensures the image covers the entire square
                          }}
                        ></div>
                      </div>

                      <div className="flex flex-col ml-3 font-sans">
                        <div className="text-sm font-semibold text-rb-gray-400 mb-2.5 flex justify-between">
                          {sentenceCase(document.title)}
                        </div>
                        {document.type === 'Artifact' && (
                          <div className="text-rb-gray-300 text-xs">
                            {document?.metadata?.description}
                          </div>
                        )}
                        {document.type === 'Guide' && (
                          <div className="text-rb-gray-300 text-xs">
                            <span>{document?.metadata?.steps} Steps</span>
                            <span className="mx-1">&middot;</span>
                            <span>{document?.metadata?.duration} min read</span>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="w-[125px] flex items-center justify-center">
                      <Button
                        href={`${document.url}?learningGoalSessionId=${activeSession.externalId}`}
                        target="_blank"
                        onClick={() => handleViewDocument(documentStepKey)}
                        variant="outline"
                        className="w-[125px] rounded-2 py-3 ml-3 px-2 border-rb-gray-300 font-sans font-normal"
                      >
                        {activeSession.data?.completedTodos?.includes(documentStepKey) ? (
                          <div className="flex items-center font-sans font-semibold text-sm text-rb-gray-400">
                            <CheckCircle className="mr-2" height={16} width={16} />
                            Viewed
                          </div>
                        ) : (
                          `View ${document.type.toLowerCase()}`
                        )}
                      </Button>
                    </div>
                  </div>
                )
              })}

            <div className="flex flex-col justify-center font-sans flex-1 mb-6 w-full">
              <div className="text-xl font-medium mb-2">Try it yourself</div>
              <div className="text-base text-rb-gray-300 font-normal mb-4">
                Choose one or more of these ways to apply this material now
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {!!step?.draftTemplate?.templateKind &&
                !!step?.draftTemplate?.promptKey &&
                !!step?.draftTemplate?.name ? (
                  <AiDraftCard
                    iconImg={DraftImg}
                    action={'draft'}
                    title={'Generate a draft'}
                    customClick={() => {
                      step.draftChatId
                        ? handleViewDraft(
                            step.draftChatId,
                            step.draftTemplate!.promptKey!,
                            step.draftTemplate!.name!
                          )
                        : handleDraftClick(
                            step.draftTemplate!.promptKey!,
                            step.draftTemplate!.name!
                          )
                    }}
                    text={"Use AI to build a doc to apply what you're learning"}
                  />
                ) : (
                  <AiDraftCard
                    action={'practice'}
                    iconImg={PracticeImg}
                    title={"Let's practice"}
                    text={'Try a quick practice scenario'}
                  />
                )}

                <AiDraftCard
                  action={'takeaway'}
                  iconImg={TakeawayImg}
                  title={'Top takeaways'}
                  text={'Get key takeaways for your notes'}
                />

                <AiDraftCard
                  action={'quiz'}
                  iconImg={QuizImg}
                  title={'Quiz me'}
                  text={'Test your thinking with a short quiz'}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
